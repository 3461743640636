// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   API_BASE_URL: 'https://test-api.trukkin.com/',
//   MAPPING_PANEL_LINK: 'https://test-live-mapping-v2.trukkin.com/#/',
//   MIX_PANEL_KEY: '654b90635b2af2480c9c2b63ec7f8951',
// };
export const environment = {
  production: true,
  API_BASE_URL: 'https://api.trukkin.com/',
  MAPPING_PANEL_LINK: 'https://live-mapping-v2.trukkin.com/#/',
  MIX_PANEL_KEY: '654b90635b2af2480c9c2b63ec7f8951',
};

/*s
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
