import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  mix_panel_key: any;
  constructor() {
    this.mix_panel_key = environment.MIX_PANEL_KEY;
  }

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(): void {
    // console.log('initializing mix panel tracking');
    // mixpanel.init(this.mix_panel_key);
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    // mixpanel.track(id, action);
    // console.log(action);
  }

  alias(id: string): void {
    // mixpanel.alias(id);
  }

  identify(id: string): void {
    // mixpanel.identify(id);
  }

  setPeople(data: any = {}): void {
    // console.log(data);
    // mixpanel.people.set(data);
  }
}
