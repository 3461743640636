import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UtilService } from '../_services/util.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(public router: Router, private u: UtilService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    if (this.u.get('token')) {
      req = req.clone({
        headers: req.headers.set(
          'authorization',
          'bearer ' + this.u.get('token')
        ),
      });
    }
    if (req.reportProgress === true) {
    }

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // this.toastr.success(event.body.message);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.error.statusCode === 401) {
              this.u.remove('token');
              this.u.remove('userData');
              this.router.navigate(['/login']);
              //toastr error comes here
            } else {
              //toastr error comes here
            }
          } else {
            //toastr comes here
          }
        }
      )
    );
  }
}
