import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { UtilService } from '../_services/util.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private u: UtilService) {}
  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['/not-reachable']);
      return false;
    } else return true;
  }

  isAuthenticated(): boolean {
    const token = this.u.get('token');
    return !!token;
  }
}
