import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { API_CONSTANT } from '../_constants/api.constants';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiEndpoint: any;
  apiConstants: any;

  constructor(private http: HttpClient) {
    this.apiConstants = API_CONSTANT;
    this.apiEndpoint = environment.API_BASE_URL;
  }

  // ------------------ 'GET' API REQUESTS
  getData(url, obj, load) {
    let params = new HttpParams();
    // ---------------- LOOKING FOR ANY FALSE KEYS(undefined, null, empty, 0)
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
        params = params.set(key, obj[key]);
      }
    });
    return this.http.get<any>(this.apiEndpoint + url, {
      params,
      reportProgress: load,
    });
  }

  // ------------------- 'POST' API REQUESTS
  postData(url, obj, load) {
    const formData = new FormData();
    // ---------------- LOOKING FOR ANY FALSE KEYS(undefined, null, empty, 0)
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== undefined && obj[key] !== null) {
        if (typeof obj[key] === 'object' && obj[key].length) {
          formData.append(key, JSON.stringify(obj[key]));
        } else if (typeof obj[key] === 'object' && !obj[key].length) {
          // --------- any state meeting the condition comes here
        } else formData.append(key, obj[key]);
      }
    });
    return this.http.post<any>(this.apiEndpoint + url, formData, {
      reportProgress: load,
    });
  }
}
