import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ObserversService {
  constructor() {}

  // ------------------ When you change country from profile bar
  public CountryData = new BehaviorSubject<any>(null);
  onCountryChange(data) {
    this.CountryData.next(data);
  }
  // -----------------------------------------------------------

  // ------------------ When you change filters from map, drivers
  public FilterData = new BehaviorSubject<any>(null);
  onFiltersChange(data) {
    this.FilterData.next(data);
  }
  // -----------------------------------------------------------

  // ------------------ When data for map is changed through map or drivers section
  public MapData = new BehaviorSubject<any>(null);
  onMapDataChange(data) {
    this.MapData.next(data);
  }
  // -----------------------------------------------------------

  // ------------------ When label is toggled in map section
  public MapLabelData = new BehaviorSubject<any>(null);
  onMapLabelChange(data) {
    this.MapLabelData.next(data);
  }
  // -----------------------------------------------------------

  // ------------------ When any data is selected, to zoom on map and recenter
  public ZoomThisData = new BehaviorSubject<any>(null);
  onZoomThisData(data) {
    this.ZoomThisData.next(data);
  }
  // -----------------------------------------------------------

  // ------------------ When any data is received for assets screen from sockets
  public assetDataSockets = new BehaviorSubject<any>(null);
  onAssetDataSockets(data) {
    this.assetDataSockets.next(data);
  }
  // -----------------------------------------------------------
}
