import { Component } from '@angular/core';
import { UtilService } from './_services/util.service';
import { MixpanelService } from './_services/mixpanel.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  checkOfflineStatus = false;
  constructor(
    private u: UtilService,
    private mixpanelService: MixpanelService
  ) {
    this.u.createOnline$().subscribe((isOnline) => {
      if (isOnline && this.checkOfflineStatus) {
        this.u.getInternetStatus(true);
        // this.u.success("Internet Connected, Refresh Page!");
      } else if (!isOnline) {
        this.u.getInternetStatus(false);
        this.checkOfflineStatus = true;
        // this.u.error("Internet Connection Lost!");
      }
    });
  }

  ngOnInit() {
    this.mixpanelService.init();
  }
}
