import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../_services/api.service';
import { UtilService } from '../../_services/util.service';
import { API_CONSTANT } from '../../_constants/api.constants';
import { MixpanelService } from 'src/app/_services/mixpanel.service';

@Component({
  selector: 'app-authenticating',
  templateUrl: './authenticating.component.html',
  styleUrls: ['./authenticating.component.css'],
})
export class AuthenticatingComponent implements OnInit {
  token: any;
  selectedCountry: any;
  apiConstant = API_CONSTANT;
  redirect = true;
  username: any;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private u: UtilService,
    private mixpanelService: MixpanelService
  ) {
    this.activeRoute.queryParams.subscribe((params) => {
      if (params['key']) {
        this.token = params['key'];
        this.selectedCountry = params['country'];
        this.u.set('token', this.token);
        this.u.set('selectedCountries', JSON.parse(this.selectedCountry));
        this.verifyToken();
      } else {
        this.router.navigate(['/not-reachable']);
      }
    });
  }

  ngOnInit(): void {}

  verifyToken() {
    let obj = { workingCountry: this.selectedCountry };
    this.api
      .postData(this.apiConstant.VALIDATE.VALIDATING_USER.URL, obj, true)
      .subscribe(
        (res) => {
          if (res) {
            this.mixPanelSettings(res);
            this.redirect = true;
            this.u.set('userData', res.data);
            console.log(res.data);
            this.u.set('token', this.token);
            this.router.navigate(['/assets']);
          }
        },
        (error) => {
          this.redirect = false;
          this.router.navigate(['/not-reachable']);
        }
      );
  }

  mixPanelSettings(res) {
    this.mixpanelService.identify(res.data['email']);
    this.mixpanelService.setPeople({
      $email: res.data['email'],
      $name: res.data['name'],
    });
  
  }
}
