import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AuthenticatingComponent } from './_layouts/authenticating/authenticating.component';
import { UnreachableComponent } from './_layouts/unreachable/unreachable.component';
import { AuthGuardService } from './_helpers/auth.guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'assets',
    pathMatch: 'full',
  },
  {
    path: 'redirecting-user',
    component: AuthenticatingComponent,
  },
  {
    path: 'track',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./_layouts/tracking/tracking.module').then(
            (m) => m.TrackingModule
          ),
      },
    ],
  },
  {
    path: 'not-reachable',
    component: UnreachableComponent,
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./_layouts/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'not-reachable',
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
