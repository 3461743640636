import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, fromEvent, merge, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private modals: any[] = [];

  constructor(private toastr: ToastrService) {}

  // for internetConnection
  private checkOnline = new BehaviorSubject<any>(null);
  public onlineStatus = this.checkOnline.asObservable();
  getInternetStatus(status) {
    this.checkOnline.next(status);
  }
  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  // ------------------ Observable for any new location
  public newLocationData = new BehaviorSubject(Array);
  currentLocation = this.newLocationData.asObservable();

  onNewLocationData(data) {
    this.newLocationData.next(data);
  }
  // ---------------------------------------------------

  // ------------------ Observable for any new location
  public newDriverData = new BehaviorSubject(Array);
  currentDriver = this.newDriverData.asObservable();

  onNewDriverData(data) {
    this.newDriverData.next(data);
  }
  // ---------------------------------------------------

  get(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    } else return null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key) {
    localStorage.removeItem(key);
  }

  setTouched(form) {
    Object.keys(form.controls).forEach((key) => {
      form.controls[key].markAsTouched({ onlySelf: true });
    });
  }

  noWhitespaceValidator(control: FormControl) {
    if (control.value) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  // -------------- TOASTR METHODS -----------------------------
  error(message) {
    this.toastr.error(message, 'Uh-Oh!');
  }

  success(message) {
    this.toastr.success(message, 'Hurray!');
  }

  warning(message) {
    this.toastr.warning(message, 'Hmmm!');
  }

  // ---------------- MODAL METHODS ----------------

  addModal(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  removeModal(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  openModal(id: string) {
    // open modal specified by id
    const modal = this.modals.find((x) => x.id === id);
    modal.open();
  }

  closeModal(id: string) {
    // close modal specified by id
    const modal = this.modals.find((x) => x.id === id);
    modal.close();
  }

  // --------------- DIRECTION RENDERING FROM GOOGLE MAPS
  calculateAndDisplayRoute() {
    let directionsService = new google.maps.DirectionsService();
    let directionsRenderer = new google.maps.DirectionsRenderer();
    return new Promise((resolve) => {
      directionsService.route(
        {
          origin: {
            query: 'gallup, nm',
          },
          destination: {
            query: 'los angeles, ca',
          },
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            resolve(directionsRenderer.setDirections(response));
          } else {
            window.alert('Directions request failed due to ' + status);
          }
        }
      );
    });
  }

  getAddressusingLatLong(lat1, long1) {
    return new Promise((resolve) => {
      let lat = parseFloat(lat1);
      let lng = parseFloat(long1);
      let latlng = new google.maps.LatLng(lat, lng);
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[1]) {
            resolve(results[1].formatted_address);
          }
        }
      });
    });
  }
}
