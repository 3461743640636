import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { TrackingComponent } from './_layouts/tracking/tracking.component';
import { AuthenticatingComponent } from './_layouts/authenticating/authenticating.component';
import { UnreachableComponent } from './_layouts/unreachable/unreachable.component';

import { ApiService } from './_services/api.service';
import { UtilService } from './_services/util.service';
import { AuthGuardService } from './_helpers/auth.guard.service';
import { InterceptorService } from './_helpers/interceptor.service';
import { ObserversService } from './_helpers/observers.service';

@NgModule({
  declarations: [
    AppComponent,
    TrackingComponent,
    AuthenticatingComponent,
    UnreachableComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      progressAnimation: 'increasing',
      resetTimeoutOnDuplicate: true,
      progressBar: true,
    }),
  ],
  providers: [
    UtilService,
    ApiService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    ObserversService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
